.lightbox-img{
    position: relative;
    display: block;
}

.btn-lightbox{
    position: absolute;
    right: calc(-18rem/16);
    bottom: calc(-18rem/16);
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: 2px solid #fff;
    border-radius: 50%;
    content: '';
    opacity: 0;
    transform: scale3d(0.65,0.65,1);
    transform-origin: bottom right;
    transition: opacity 0.35s, transform 0.35s;
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    padding: 0;
    color: #fff;
    z-index: 2;
}

.btn-lightbox .icon{
    position: relative;
    top: calc(-7rem/16);
    left: calc(-5rem/16);
    font-size: calc(9rem/16);
}

.lightbox-img:hover .btn-lightbox {
    opacity: 1;
    transform: scale3d(1,1,1);
}
