.flyin{
    padding: calc(18rem/16);
    background-color: var(--color-primary);
    color: #fff;
    font-size: calc(18rem/16);

    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;

    @media screen and (max-width: 767px){
        right: 0;

    }
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        padding: calc(50rem/16);
        top: calc(50rem/16);
    }
}

.flyin-overlay{
    transform: translateX(-100%);
    transition: all 250ms ease;
    
    @media screen and (min-width: 768px){
        max-width: calc(695rem/16);

    }
    @media screen and (max-width: 767px){
       padding: calc(20rem/16);
        font-size: calc(18rem/16);
    }
}

.flyin-overlay.is-open{
    transform: translateX(0);
}

.flyin__close{
    background-color: transparent;
    border: none;
    color: #fff;
    padding: calc(5rem/16);
    position: absolute;
    top: calc(15rem/16);
    right: calc(15rem/16);
    font-size: calc(15rem/16);
}

.status-light{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0;
    color: #fff;
}

.status-light:not(:first-child){
    margin-top: calc(20rem/16);
}

.status-light__circle{
    width: calc(26rem/16);
    height: calc(26rem/16);
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: calc(10rem/16);

    @media screen and (min-width: 768px){
        width: calc(32rem/16);
        height: calc(32rem/16);
    }
}

.status-light__circle-inner{
    width: calc(16rem/16);
    height: calc(16rem/16);
    border-radius: 50%;
    display: inline-block;

    @media screen and (min-width: 768px){
        width: calc(22rem/16);
        height: calc(22rem/16);
    }
}

.status-light.status-low .status-light__circle-inner{
    background-color: #89c394;
}

.status-light.status-high .status-light__circle-inner{
    background-color: #f1ad68;
}

.status-light.status-full .status-light__circle-inner{
    background-color: #df6858;
}

.status-light.status-not-open .status-light__circle-inner{
    background-color: #BEC2C6;
}

.status-light__arrow{
    font-size: calc(10rem/16);
    margin-left: calc(10rem/16);
    position: relative;
    top: calc(1rem/16);
    transition: all 250ms ease;

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
    }
}

.status-light:hover .status-light__arrow{
    transform: translateX(3px);
}

.flyin-badge .status-light{
    @media screen and (max-width: 767px){
       margin-top: 0;
        margin-left: calc(15rem/16);
    }
}

.flyin-badge{
    @media screen and (max-width: 767px){
       display: flex;
        align-items: center;
        font-size: 16px;
    }
}

.flyin__title{
    @media screen and (max-width: 767px){
        font-size: calc(25rem/16);
    }
}

.flyin .wysiwyg{
    @media screen and (max-width: 767px){
       font-size: calc(18rem/16);
        line-height: 1.4;
    }
}
