.footer{
    font-size: calc(16rem/16);
    line-height: calc(20/13);
    position: relative;
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(80rem/16);
        font-size: calc(13rem/16);
    }
    
    @media screen and (max-width: 767px){
       padding-bottom: calc(60rem/16);
    }
}

.footer:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #FFFFFF 0%, #EAF4F7 100%);
    opacity: 0.6;
    z-index: 1;
}

.footer a:hover{
    color: #378a81;
}

.footer__bg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    background-size: cover;
    background-position: center;
}

.footer__top{
    padding: calc(50rem/16);
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px){
        padding: calc(120rem/16) 0;
    }
}

.footer__hl{
    text-transform: uppercase;
    font-family: var(--font-default-medium);
    font-size: calc(18rem/16);
    margin-bottom: calc(15rem/16);
}

.footer__hl-big{
    @media screen and (max-width: 767px){
       font-size: calc(25rem/16);
        letter-spacing: calc(0.83rem/16);
        line-height: calc(40/25);
    }
}

.footer__link-list>li{
    margin-bottom: calc(8rem/16);
}

.footer__social-links{

    margin-top: calc(40rem/16);
    margin-bottom: calc(40rem/16);

    @media screen and (min-width: 768px){
       margin-top: calc(20rem/16);
    }
}

.footer__social-links>li{
    position: relative;
    padding: 0 calc(10rem/16) 0 calc(17rem/16);

    @media screen and (min-width: 768px){
        padding: 0 calc(7rem/16) 0 calc(14rem/16);
    }
}

.footer__social-links>li:not(:first-child):before{
    content: '';
    position: absolute;
    left: 0;
    width: calc(1rem/16);
    height: calc(30rem/16);
    background-color: var(--color-primary);
    top: calc(1rem/16);
}


.footer__social-links__item{
    font-size: calc(30rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(26rem/16);
    }
}

.footer__bottom{
    position: relative;
    z-index: 1;
    padding: 0 calc(50rem/16);

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) 0;
        border-top: 2px solid #3b50754d;
    }
}

.footer__inline-link-list>li{
    position: relative;
    padding: 0 calc(8rem/16) 0 calc(3rem/16);
    margin: 0;
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(7rem/16);
    }
}

.footer__inline-link-list>li:not(:last-child):before{
    content: '';
    position: absolute;
    right: 0;
    width: calc(1rem/16);
    height: calc(18rem/16);
    background-color: var(--color-primary);
}

.footer__inline-link-list>.list-inline-item:not(:last-child){
    margin: 0;

    @media screen and (max-width: 767px){
        margin-bottom: calc(7rem/16);
    }
}

.site-by-valantic {
    height: calc(16rem/16);
    
    @media screen and (max-width: 767px){
       margin-top: calc(25rem/16);
       margin-bottom: calc(20rem/16);
        text-align: center;
    }
}

.accordion-footer .card{
    background-color: transparent;
    border-color: transparent;
}

.accordion-footer .card-header{
    background-color: transparent;
    border-color: transparent;
    font-size: calc(16rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-medium);
    padding: calc(7rem/16) 0;
}

.accordion-footer__toggle-icon{
    font-size: calc(5rem/16);
    transform: rotate(180deg);
}

.collapsed .accordion-footer__toggle-icon{
    transform: rotate(0);
}

.accordion-footer .card-body{
    padding: calc(5rem/16) calc(10rem/16);
}