.console{
    position: fixed;
    z-index: 20;
    right: 0;

    @media screen and (min-width: 768px){
        top: calc(180rem/16);
    }

    @media screen and (max-width: 767px){
        bottom: 0;
        left: 0;
    }

    @media screen and (min-width: 992px){
        top: calc(300rem/16);
    }
}

.console__item{
    height: calc(60rem/16);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    transition: all 250ms ease;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    font-size: calc(20rem/16);
    @media screen and (min-width: 768px){
        width: calc(60rem/16);
        margin-bottom: calc(10rem/16);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    }
}

.console__item:hover{
    background-color: var(--color-primary);
    color: #fff;
}

.console ul{
    @media screen and (max-width: 767px){
        display: flex;
        flex-wrap: wrap;
    }
}

.console ul>li{
    @media screen and (max-width: 767px){
        flex: 1 1 auto;
    }
}