@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1728461837058/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1728461837058/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1728461837058/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-arrow-down:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-arrow-up:before { content: "\EA05" }
.icon-calendar:before { content: "\EA06" }
.icon-close:before { content: "\EA07" }
.icon-dropdown_down:before { content: "\EA08" }
.icon-dropdown_up:before { content: "\EA09" }
.icon-elements:before { content: "\EA0A" }
.icon-euro:before { content: "\EA0B" }
.icon-facebook:before { content: "\EA0C" }
.icon-gift:before { content: "\EA0D" }
.icon-instagram:before { content: "\EA0E" }
.icon-linkedin:before { content: "\EA0F" }
.icon-mail:before { content: "\EA10" }
.icon-minus:before { content: "\EA11" }
.icon-phone:before { content: "\EA12" }
.icon-pinterest:before { content: "\EA13" }
.icon-play:before { content: "\EA14" }
.icon-plus:before { content: "\EA15" }
.icon-route:before { content: "\EA16" }
.icon-sauna:before { content: "\EA17" }
.icon-search:before { content: "\EA18" }
.icon-shop:before { content: "\EA19" }
.icon-spa:before { content: "\EA1A" }
.icon-time:before { content: "\EA1B" }
.icon-twitter:before { content: "\EA1C" }
.icon-whatsapp:before { content: "\EA1D" }


:root {
--icon-addthis: "\EA01";
    --icon-arrow-down: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-arrow-up: "\EA05";
    --icon-calendar: "\EA06";
    --icon-close: "\EA07";
    --icon-dropdown_down: "\EA08";
    --icon-dropdown_up: "\EA09";
    --icon-elements: "\EA0A";
    --icon-euro: "\EA0B";
    --icon-facebook: "\EA0C";
    --icon-gift: "\EA0D";
    --icon-instagram: "\EA0E";
    --icon-linkedin: "\EA0F";
    --icon-mail: "\EA10";
    --icon-minus: "\EA11";
    --icon-phone: "\EA12";
    --icon-pinterest: "\EA13";
    --icon-play: "\EA14";
    --icon-plus: "\EA15";
    --icon-route: "\EA16";
    --icon-sauna: "\EA17";
    --icon-search: "\EA18";
    --icon-shop: "\EA19";
    --icon-spa: "\EA1A";
    --icon-time: "\EA1B";
    --icon-twitter: "\EA1C";
    --icon-whatsapp: "\EA1D";
    
}