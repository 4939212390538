.accordion .card,
.accordion .card:first-of-type,
.accordion .card:not(:first-of-type):not(:last-of-type){
    border: none;
    border-bottom: 2px solid var(--color-primary-light);
}

.accordion .card:first-of-type{
    border-top: 2px solid var(--color-primary-light);
}

.accordion .card-header{
    padding: 0;
    background-color: #fff;
    border-bottom: 0;
}

.accordion .accordion__header-link{
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
    text-transform: none;
    line-height: calc(26/20);
    letter-spacing: 0;
    font-family: var(--font-default);
    font-size: calc(20rem/16);
    padding: calc(15rem/16) calc(20rem/16) calc(15rem/16) calc(20rem/16);
    background-color: var(--color-primary-light);
    border-left: 4px solid;
    border-bottom: var(--color-primary-light);
    
    @media screen and (min-width: 768px){
        padding: calc(35rem/16) calc(40rem/16) calc(35rem/16) calc(145rem/16);
    }
}

.accordion .accordion__header-link.collapsed:hover{
    background-color: var(--color-primary-light);
}

.accordion .accordion__header-link.collapsed{
    background-color: #fff;
    border-left: 4px solid transparent;
}

.accordion__toggle-icon{
    font-size: calc(12rem/16);
    transform: rotate(180deg);
}

.accordion .accordion__header-link.collapsed .accordion__toggle-icon{
    transform: rotate(0);
}

.accordion .card-body{
    padding: calc(15rem/16) calc(20rem/16) calc(15rem/16) calc(20rem/16);
    background-color: var(--color-primary-light);
    border-bottom: 2px solid #fff;
    border-left: 4px solid;

    @media screen and (min-width: 768px){
        padding: calc(5rem/16) calc(40rem/16) calc(35rem/16) calc(145rem/16);
    }
}