html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/

    @media screen and (min-width: 768px) and (max-width: 991px) {
        /* Table scaling */
        font-size: calc(16 / (991 / 100) * 1vw);
    }

    @media screen and (min-width: 2000px) and (max-width: 3999px) {
        /*Scale the whole page up on bigger screens*/
        font-size: calc(16vw / 2000 * 100);
    }

    @media screen and (min-width: 4000px) {
        font-size: 32px;
    }

}
body {
    position: relative;
    background-color: var(--color-primary-light);

    @media screen and (max-width: 767px) {
        min-width: calc(320rem / 16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

body.nav-open{
    overflow: hidden;
}

.main-content{
    padding-top: calc(64rem/16);

    @media screen and (min-width: 768px){
       padding-top: calc(80rem/16);
    }
}

.container-outer{
    max-width: calc(1920rem/16);
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 0 calc(16rem/16) 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    overflow: hidden;
}

.container.container {
    max-width: calc(1739rem/16); /* 1705px +34px padding */
    width: 100%;

    padding-right: calc(15rem/16);
    padding-left: calc(15rem/16);
    margin: 0 auto;

    @media screen and (min-width: 768px){
        padding-right: calc(32rem/16);
        padding-left: calc(32rem/16);
    }
}

.container-medium{
    max-width: calc(1449rem/16); /* 1415px +34px padding */
    width: 100%;
    padding-right: calc(15rem/16);
    padding-left: calc(15rem/16);
    margin: 0 auto;

    @media screen and (min-width: 768px){
        padding-right: calc(32rem/16);
        padding-left: calc(32rem/16);
    }
}

.container-narrow{
    max-width: calc(1159rem/16); /* 1125px +34px padding */
    width: 100%;
    padding-right: calc(15rem/16);
    padding-left: calc(15rem/16);
    margin: 0 auto;

    @media screen and (min-width: 768px){
        padding-right: calc(32rem/16);
        padding-left: calc(32rem/16);
    }
}