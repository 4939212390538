.main-content.main-content--infoscreen{
    padding-top: 0;
}

.table--infoscreen td,
.table--infoscreen th{
    font-size: calc(44rem/16);
}

/*
.infoscreen{
    height: 100vh;
}*/
