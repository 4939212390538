:root{
    --offset-bg-y: calc(80rem/16);
    --offset-bg-x: calc(290rem/16);
}

.wysiwyg-with-media.wysiwyg-with-media--offset-bg{
    padding: var(--offset-bg-y) 0;
    
    @media screen and (max-width: 767px){
       padding: 0;
    }
}

.wysiwyg-with-media{
    position: relative;
}

.area-offset-bg{

    @media screen and (min-width: 768px){
        top: 0;
        bottom: 0;
        right: calc(32rem/16);
        position: absolute;
        z-index: 0;
        width: 66.66667%;
    }

}

.wysiwyg-with-media.has-bg-secondary-light .area-offset-bg{
    background: var(--color-secondary-light);
}

.wysiwyg-with-media.has-bg-primary-light .area-offset-bg{
    background: var(--color-primary-light);
}

.wysiwyg-with-media.wysiwyg-with-media--offset-bg-left .area-offset-bg{
    right: auto;
    left: calc(32rem/16);
}


.wysiwyg-with-media__media{
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px){
        transition: all 900ms ease;
        transition-delay: 250ms;
        opacity: 0;
    }

}

@media screen and (min-width: 768px){
    .wysiwyg-with-media__media.is-left{
        transform: translateX(-40%);
    }

    .wysiwyg-with-media__media.is-right{
        transform: translateX(40%);
    }

    .is-in-viewport .wysiwyg-with-media__media{
        transform: translateX(0);
        opacity: 1;
    }
}


.wysiwyg-with-media__body{
    @media screen and (min-width: 768px) and (max-width: 992px){
        padding: 0 calc(15rem/16);
    }
}

@media screen and (max-width: 767px){

    .wysiwyg-with-media__body{
        padding: calc(20rem/16);
    }

    .has-bg-secondary-light .wysiwyg-with-media__body{
        background: var(--color-secondary-light);
    }

    .has-bg-primary-light .wysiwyg-with-media__body{
        background: var(--color-primary-light);
    }
}
