.video-js {
    cursor: pointer;
}
.video-js .vjs-big-play-button {
    width: calc(76rem/16);
    height: calc(76rem/16);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 50%;
    color: var(--color-primary);
    font-size: calc(35rem / 16);
    position: relative;

    @media screen and (min-width: 768px){
        width: calc(120rem/16);
        height: calc(120rem/16);
        font-size: calc(45rem / 16);
    }
}
.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button {
    background-color: var(--color-primary);
    color: #ffffff;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before{
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}