.content-block,
.container.content-block,
.pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .container.content-block,
.contact-form.content-block {
    margin-top: calc(45rem/16);
    margin-bottom: calc(45rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
        margin-bottom: calc(70rem/16);
    }
}

.content-block--portal,
.container.content-block--portal,
.pimcore_area_content > .content-block--portal,
.pimcore_area_content > .container.content-block--portal{
    margin-top: calc(45rem/16);
    margin-bottom: calc(45rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
        margin-bottom: calc(70rem/16);
    }
}

.contact-form.content-block{
    margin-bottom: calc(45rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(70rem/16);
    }
}