.title-block--main{
    margin-bottom: calc(-25rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(-30rem/16);
    }
}

.title-block__title {
    margin-bottom: calc(10rem/16);
}