.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(30rem/16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-secondary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-secondary);
    text-decoration: underline;
}

.wysiwyg table{
    @media screen and (max-width: 767px){
        display: block;
        width: 100%!important;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.wysiwyg ul{
    list-style: none;
    padding: 0;
}

.wysiwyg ul>li{
    padding-left: calc(25rem/16);
    position: relative;
}
.wysiwyg ul>li:not(:last-child){
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(20rem/16);
    }
}


.wysiwyg ul>li:before{
    content: '';
    position: absolute;
    left: 0;
    top: calc(7rem/16);
    height: calc(12rem/16);
    width: calc(12rem/16);
    background-color: var(--color-primary);
}

.wysiwyg-area__lead{
    font-family: var(--font-default-medium);
    font-size: calc(20rem/16);
    
    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);
    }
}

.wysiwyg-area__body{
    padding: calc(30rem/16);
/*    background-color: var(--color-secondary);*/

    @media screen and (min-width: 768px){
        padding: calc(50rem/16) calc(145rem/16);
    }
}