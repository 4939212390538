.form-control{
    font-size: calc(16rem/16);
    border: 1px solid #E0E1E1;
    height: calc(55rem/16);
    padding: calc(20rem/16) calc(16rem/16) calc(12rem/16);
}

.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: calc(40rem/16);
    cursor: pointer;
}
.select select::-ms-expand {
    display: none;
}

.select:before {
    font-family: "iconfont";
    content: var(--icon-dropdown_down);
    speak: none;
    position: absolute;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
    right: calc(16rem/16);
    line-height: 1;
    font-size: 5px;
    pointer-events: none;
    display: flex;
    align-items: center;
    color: var(--color-primary);
}

.parsley-errors-list{
    list-style: none;
    padding: 0;
    font-size: calc(12rem/16);
    position: absolute;
    color: var(--color-danger);
    line-height: calc(15rem/16);
    bottom: calc(2rem/16);
    right: 1.75rem;
}

.custom-checkbox .parsley-errors-list{
    left: 0;
    right: auto;
    top: 100%;
}

.has-error .form-control{
    border: 1px solid var(--color-danger);
}

.search-form .btn{
    @media screen and (max-width: 767px){
       font-size: calc(13rem/16);
        padding: calc(15rem/16);
    }
}