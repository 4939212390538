.bg-slide.hero-portal{
    height: calc(100vh - 64px);


    color: #fff;

    @media screen and (min-width: 768px){
        height: calc(100vh - 80px);
    }
}

.hero-portal{
    position: relative;
}

.hero-portal:before{
    content: '';
    background: linear-gradient(180deg, rgba(55,138,129,0) 0%, #378A81 100%, #378A81 100%);
    opacity: 0.8;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45%;
}

.hero-portal__body{
    position: absolute;
    content: '';
    z-index: 2;
}

.hero-portal__body{
    position: absolute;
    bottom: calc(65rem/16);
    width: 100%;
    padding: 0 calc(20rem/16);

    @media screen and (min-width: 992px){
        bottom: calc(185rem/16);
    }
    @media screen and (min-width: 768px){
        bottom: calc(105rem/16);
    }
}

.hero-portal .bg-slide__title{

    @media screen and (min-width: 992px){
        font-size: calc(70rem/16);

    }
}

.bg-slide__title{
    margin-bottom: calc(30rem/16);
}

.hero-portal .btn-white-outline{
    @media screen and (min-width: 768px) and (max-width: 991px){
       padding: calc(13rem/16) calc(20rem/16);
    }
    
    @media screen and (max-width: 767px){
        padding: calc(10rem/16) calc(20rem/16);

    }
}

.hero-portal .bg-slide__btn-list .list-inline-item:not(:last-child),
.hero-portal .bg-slide__btn-list li{
    @media screen and (min-width: 992px){
        margin-right: calc(25rem/16);
    }
    @media screen and (max-width: 767px){
        margin-right: 0;
    }
}