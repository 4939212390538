.slider--hide-dots .slick-dots{
    display: none!important;
}

.slider .slick-slide{
     margin: 0 calc(10rem/16);

    @media screen and (min-width: 768px){
        margin: 0 calc(17rem/16);
    }
 }

.slider__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    font-size: calc(36rem/16);
    color: white;
    text-shadow: 0 0 7px black;
    padding: calc(3rem/16) calc(12rem/16);
}

.slider__arrow.slick-next{
    right: calc(10rem/16);

    @media screen and (min-width: 768px){
        right: calc(100rem/16);
    }
}

.slider__arrow.slick-prev{
    left: calc(10rem/16);

    @media screen and (min-width: 768px){
        left: calc(100rem/16);
    }
}

.slider__arrow.slick-disabled{
    opacity: 0.3;
}