.content-teaser{
    background-color: var(--color-primary-light);
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.content-teaser__body{
    padding: calc(30rem/16);
}

.content-teaser__bottom{
    padding: 0 calc(30rem/16) calc(30rem/16);
}

.content-teaser img{
    transform: scale(1.08);
    transition: all 550ms ease;
}

.content-teaser:hover img{
    transform: scale(1);
}