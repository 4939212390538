/*Margin Helper*/


/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.font-default{ font-family: var(--font-default); }
.font-default-medium{ font-family: var(--font-default-medium); }
.font-default-strong{ font-family: var(--font-default-bold); }


/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }


.js-go-to-link{
    cursor: pointer;
}

.bg-primary-light{
    background-color: var(--color-primary-light);
    color: var(--color-primary);
}

.bg-secondary-light{
    background-color: var(--color-secondary-light);
    color: var(--color-primary);
}

.w-md-auto{
    @media screen and (min-width: 768px){
       width: auto!important;
    }
}

.modal--popup .modal-header{
    border: none;
}

.modal--popup .modal-body{
    @media screen and (max-width: 767px){
        padding-top: 0;
        margin-top: -30px;
    }

}