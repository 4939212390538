.nav-tabs.nav-tabs--primary{
    border: none;
}

.nav-tabs.nav-tabs--primary .nav-link{
    border: 1px solid var(--color-primary);
    padding: calc(10rem/16) calc(18rem/16);
    font-family: var(--font-default-medium);
    color: var(--color-primary);

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(25rem/16);
    }
    
    @media screen and (min-width: 768px) and (max-width: 1400px){
       font-size: calc(16rem/16);
    }
}

.nav-tabs.nav-tabs--primary .nav-item.show .nav-link,
.nav-tabs.nav-tabs--primary .nav-link.active{
    background-color: var(--color-primary);
    color: #fff;
}

.nav-tabs.nav-tabs--primary .nav-link:not(.active):hover{
    background-color: var(--color-primary-light);
}

.nav-fill-xs{
    @media screen and (max-width: 767px){
        display: flex;
    }
}

.nav-fill-xs>li {
    @media screen and (max-width: 767px){
        flex: 1;
    }
}


/* .nav-tabs--season x*/

.nav-tabs.nav-tabs--season{
    border: none;
    
    @media screen and (max-width: 767px){
       display: flex;
        flex-wrap: wrap;
    }
}

.nav-tabs.nav-tabs--season>li {
    @media screen and (max-width: 767px){
        flex: 1;
    }
}

.nav-tabs.nav-tabs--season .nav-item{
    @media screen and (min-width: 768px){
        max-width: calc(255rem/16);
    }
}

.nav-tabs.nav-tabs--season .nav-link{
    border: 2px solid #BFB9B1;;
    padding: calc(18rem/16) calc(20rem/16);
    font-family: var(--font-default-medium);
    color: #BFB9B1;
    text-align: center;
    line-height: calc(25/20);

    @media screen and (min-width: 768px){
        padding: calc(18rem/16) calc(20rem/16);
    }
}

.nav-tabs.nav-tabs--season .nav-item.show .nav-link,
.nav-tabs.nav-tabs--season .nav-link.active{
    border-color: var(--color-primary);
    color: var(--color-primary);
}

.nav-tabs.nav-tabs--season .nav-link .icon{
    font-size: calc(30rem/16);
    margin-bottom: calc(10rem/16);
}

.nav-tabs--season .fake-radio{
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
    border: 2px solid #BFB9B1;
    display: block;
    margin: 0 auto;
    margin-top: calc(10rem/16);
    position: relative;
}
.nav-tabs--season .fake-radio:before{
    content: '';
    width: calc(10rem/16);
    height: calc(10rem/16);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: transparent;
}

.nav-tabs.nav-tabs--season .nav-item.show .nav-link .fake-radio:before,
.nav-tabs.nav-tabs--season .nav-link.active .fake-radio:before{
    background-color: var(--color-primary);
}

.nav-tabs.nav-tabs--season .nav-link:not(.active):hover .fake-radio:before{
    background-color: #BFB9B1;
}

.nav-tabs.nav-tabs--season .nav-item.show .nav-link .fake-radio,
.nav-tabs.nav-tabs--season .nav-link.active .fake-radio{
    border-color: var(--color-primary);
}

.nav-tabs--season>li:not(:last-child){
    margin-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(35rem/16);
    }
}

.nav-tabs--season__text{
    @media screen and (max-width: 767px){
       word-break: break-word;
        font-size: calc(16rem/16);
    }
}