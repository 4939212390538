.img-teaser{
    position: relative;
}

.img-teaser:before{
    content: '';
    position: absolute;
    width: 47%;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: var(--color-primary-light);
}

.img-teaser-row ul>li:nth-child(2n) .img-teaser:before{
    background-color: var(--color-secondary-light);
}

.img-teaser__title{
    margin-bottom: calc(20rem/16);
    position: relative;
    z-index: 1;
    
    @media screen and (min-width: 768px) and (max-width: 1200px){
       font-size: calc(25rem/16);
    }
}

.img-teaser__btn{
    position: absolute;
    bottom: calc(30rem/16);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    white-space: nowrap;

    @media screen and (min-width: 768px) and (max-width: 1200px){
        font-size: calc(16rem/16);
    }
}

.img-teaser__img{
    position: relative;
}

.img-teaser__img:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(180deg, rgba(55,138,129,0) 0%, #378A81 100%, #378A81 100%);
    z-index: 1;
}

.img-teaser img{
    transform: scale(1.08);
    transition: all 550ms ease;
}

.img-teaser:hover img{
    transform: scale(1);
}