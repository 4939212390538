:root {
    --navbar-height: calc(64rem/16);
    @media screen and (min-width: 768px){
        --navbar-height: calc(80rem/16);
    }
}

.navbar{
    font-size: calc(16rem/16);
    font-family: var(--font-default-medium);
    text-transform: uppercase;
    height: var(--navbar-height);
    border-bottom: 1px solid #E0E1E1;
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 500;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        padding: 0 calc(60rem/16) 0 calc(30rem/16);
        height: var(--navbar-height);
    }

    @media screen and (max-width: 767px){
        padding: 0;
    }
}

.navbar-brand{
    padding: 0;
    @media screen and (max-width: 767px){
       margin: 0;
    }
}

.navbar-brand img{
    @media screen and (min-width: 768px){
        width: calc(120rem/16);
    }
    
    @media screen and (max-width: 767px){
       height: calc(64rem/16);
    }
}

.navbar-nav{
    @media screen and (max-width: 767px){
        display: block;
        width: 100%;
    }
}

.navbar-nav>li.nav-item{
    position: relative;

    @media screen and (min-width: 768px){
       height: var(--navbar-height);
    }
    
    @media screen and (max-width: 767px){
       padding: 0;
    }
}

.navbar-nav>li.nav-item.nav-item--subnav-big {
    @media screen and (min-width: 768px) and (max-width: 991px){
       position: static;
    }
}

.navbar-expand-md .navbar-nav>li>.nav-link {
    @media screen and (min-width: 768px){
        padding-right: calc(25rem/16);
        padding-left: calc(25rem/16);
        height: 100%;
        display: flex;
        align-items: center;
        font-size: calc(16rem/16);
    }

    @media screen and (min-width: 1300px){
        padding-right: calc(50rem/16);
        padding-left: calc(50rem/16);
        font-size: calc(18rem/16);
    }
}

.navbar-nav .nav-item.active>.nav-link{
    color: #8ACBC1;
}

.navbar-nav .nav-item:hover>.nav-link{
    @media screen and (min-width: 768px){
        color: #8ACBC1;
    }
}

.navbar-nav__subnav{
    position: relative;
    left: 0;
    right: 0;
    top: 100%;
    padding: 1rem;
    background-color: #fff;
    z-index: 100;
    min-width: calc(270rem/16);
    display: none;

    @media screen and (min-width: 768px){
        position: absolute;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.17);
        border-top: 3px solid #8acbc1;
        min-width: calc(270rem/16);
        left: 50%;
        transform: translateX(-50%);
    }

    @media screen and (max-width: 767px){
       padding: calc(10rem/16);
        border-bottom: 1px solid #eee;
    }
}

.navbar-nav>li:first-child .navbar-nav__subnav{
    left: 0;
    transform: none;
}

.navbar-nav__subnav.navbar-nav__subnav--big{
    @media screen and (min-width: 992px){
       min-width: calc(1090rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 991px){
       transform: none;
        left: 0;
    }
}

.navbar-nav>.nav-item.is-open .navbar-nav__subnav{
    display: block;
}

.navbar-nav>.nav-item:hover .navbar-nav__subnav{
    @media screen and (min-width: 768px){
        display: block;
    }
}

.navbar-lang .list-inline-item:not(:last-child){
    margin-right: 0;
}

.navbar-lang__item{
    opacity: 0.3;
}

.navbar-lang__item:hover,
.navbar-lang__item.active{
    opacity: 1;
}

.navbar-nav__subnav__col{
    width: calc(250rem/16);

    @media screen and (min-width: 992px){
        width: calc(300rem/16);
    }

    @media screen and (min-width: 768px){
       height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.navbar-nav__subnav__col__bottom{
    @media screen and (min-width: 768px){
       margin-top: auto;
    }
}

.navbar-nav__subnav .nav-link{
    font-size: calc(16rem/16);
    text-transform: none;
    font-family: var(--font-default);
    
    @media screen and (max-width: 767px){
       padding: calc(10rem/16) 0;
    }
}

.navbar-nav__subnav__col{
    font-size: calc(16rem/16);
    text-transform: none;
    font-family: var(--font-default);
    color: #6A6A6A;
}

.navbar-nav__subnav__col__content{
    padding: calc(20rem/16);
    position: relative;
}


.navbar-nav__subnav__row>.col-md-auto:not(:last-child) .navbar-nav__subnav__col__content:before {
    content: '';
    position: absolute;
    right: calc(-18rem/16);
    bottom: 0;
    top: 0;
    width: calc(1rem/16);
    background-color: #D6D6D6;

}


@media screen and (max-width: 767px){

    .navbar-collapse {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: calc(64rem/16);
        background-color: #fff;
        color: var(--color-primary);
        transform: translateX(100%);
        transition: transform .25s ease;
        padding: calc(10rem/16) calc(20rem/16) calc(20rem/16);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 20;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-open .navbar-collapse{
        transform: translateX(0);
    }

    .navbar-toggler{
        padding: 0;
        width: calc(60rem/16);
        height: calc(58rem/16);
        background-color: #fff;
        border-radius: 0;
        margin-top: calc(4rem/16);
    }
    .navbar-toggler__bar {
        display: block;
        width: calc(27rem/16);
        height: calc(3rem/16);
        background-color: var(--color-primary);
        transition: transform .25s ease-in-out,opacity .25s ease-in-out;
        border-radius: calc(2rem/16);
        margin: 0 auto;
    }
    
    .navbar-toggler__text{
        font-size: calc(12rem/16);
        color: var(--color-primary);
        position: relative;
        top: calc(-3rem/16);
    }

    .navbar-toggler__bar+.navbar-toggler__bar {
        margin-top: calc(4rem/16);
    }

    .navbar-toggler.is-open .navbar-toggler__bar:first-child {
        transform: rotate(-45deg) translateX(-3px) translateY(6px);
        width: calc(27rem/16);
    }

    .navbar-toggler.is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }

    .navbar-toggler.is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(45deg) translateX(-4px) translateY(-7px);
        width: calc(27rem/16);
    }

    .subnav-toggle{
        position: absolute;
        padding: calc(10rem/16) calc(12rem/16);
        right: 0;
        top: calc(9rem/16);
        font-size: calc(12rem/16);
        color: var(--color-primary);
    }

    .navbar-nav{
        padding-bottom: calc(10rem/16);
        margin-bottom: calc(25rem/16);
        border-bottom: 1px solid #eee;
    }

    .navbar-nav>li>.nav-link{
        padding: calc(18rem/16) calc(30rem/16);
        margin: 0 calc(-20rem/16);
    }

    .navbar-nav>li.nav-item.is-open .subnav-toggle .icon{
        transform: rotate(45deg);
    }
    .navbar-nav>li.nav-item.is-open>.nav-link{
        background-color: var(--color-secondary-light);
    }

    .navbar-nav__bottom-links{
        margin-top: calc(30rem/16);
        text-transform: none;
        font-family: var(--font-default);
    }

    .navbar-search{
        width: calc(60rem/16);
        height: calc(60rem/16);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(21rem/16);
    }
}


