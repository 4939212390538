.btn{
    font-family: var(--font-default-medium);
    
    @media screen and (max-width: 991px){
       font-size: calc(16rem/16);
    }
 
}

.btn-primary-light{
    background-color: var(--color-primary-light);
}

.btn-secondary-light{
    background-color: var(--color-secondary-light);
}

.btn-icon__icon,
.btn-icon-text__icon{
   vertical-align: calc(-2rem/16);
}

.btn-no-styling{
    padding: 0;
    background: none;
    border: none;
}

.btn-white-outline{
    color: #fff;
    border: 2px solid #fff;
    padding: calc(13rem/16) calc(40rem/16);

    @media screen and (min-width: 768px){
        padding: calc(13rem/16) calc(50rem/16);
    }
}

.btn-white-outline:hover{
    color: var(--color-primary);
    background-color: #fff;
}

.btn-primary-outline{
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    padding: calc(10rem/16) calc(40rem/16);

    @media screen and (min-width: 992px){
        padding: calc(12rem/16) calc(50rem/16);
    }

    @media screen and (min-width: 768px){
        padding: calc(12rem/16) calc(30rem/16);
    }
}

.btn-primary-outline:hover{
    color: #fff;
    background-color: var(--color-primary);
}
