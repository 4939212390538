.bg-slide{
    position: relative;
    height: 70vh;
    width: 100vw;
    max-width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    color: #fff;

    @media screen and (min-width: 768px){
        height: 100vh;
    }
}

.bg-slide:not(.hero-portal):before{
    content: '';
    position: absolute;
    width: 50%;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(to left,  rgba(53,103,98,1) 0%,rgba(53,103,98,0) 81%,rgba(53,103,98,0) 100%);
    opacity: 0.8;
}

.bg-slide--text-left.bg-slide:not(.hero-portal):before{
    left: 0;
}

.bg-slide.bg-slide--text-left:before{
    transform: scaleX(-1);
}

.bg-slide__title,
.bg-slide__text{
    @media screen and (min-width: 768px){
        text-shadow: 0 2px 4px rgba(0,0,0,0.2);
    }
}

.bg-slide__btn-list .list-inline-item:not(:last-child){
    @media screen and (min-width: 768px){
        margin-right: calc(35rem/16);
    }
}

.bg-slide__btn-list .list-inline-item:not(:last-child),
.bg-slide__btn-list li{
    @media screen and (max-width: 767px){
        display: block;
        margin-bottom: calc(10rem/16);
    }
}

.bg-slide__btn-list li a{
    @media screen and (max-width: 767px){
       display: block;
        width: 100%;
    }
}

.bg-slide__body{
    position: absolute;
    bottom: calc(70rem/16);
    max-width: calc(545rem/16);

    @media screen and (min-width: 992px){
        bottom: calc(135rem/16);
    }
}

.bg-slide--text-right .bg-slide__body{
    right: calc(100rem/16);

    @media screen and (min-width: 992px){
        right: calc(250rem/16);
    }
}

.bg-slide--text-left .bg-slide__body{
    left: calc(100rem/16);

    @media screen and (min-width: 992px){
        left: calc(250rem/16);
    }
}

.bg-slide__btn-list .bg-slide__btn-list--icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 3.5rem;
    width: 3.5rem;
    transform: translateY(-40%) translateX(35%);
    background-color: var(--color-primary);
    border-radius: 100%;
    transition: all 0.15s ease-in-out;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    @media screen and (max-width: 767px){
        transform: translateY(-13%) translateX(32%);
    }
}

.bg-slide__btn-list .bg-slide__btn-list--icon span{
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 1.75rem;
    padding-left: 0.125rem;
    padding-bottom: 0.125rem;
}

.bg-slide__btn-list--btn:hover > .bg-slide__btn-list--icon{
    transform: translateY(-40%) translateX(35%) scale(1.05);
    @media screen and (max-width: 767px){
        transform: translateY(-13%) translateX(32%) scale(1);
    }
}

@media screen and (max-width: 767px){
    .bg-slide-mobile{
        margin-top: calc(15rem/16);
    }

   .bg-slide-mobile__body{
       padding: calc(20rem/16);
   }

    .bg-slide-mobile__img{
        position: relative;
    }

    .bg-slide-mobile__img:before{
        content: '';
        background: linear-gradient(90deg, #356762 0%, rgba(55,138,129,0) 100%);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0.5;
        z-index: 1;
    }

   .bg-slide-mobile__title{
       position: absolute;
       bottom: calc(30rem/16);
       left: calc(20rem/16);
       right: calc(20rem/16);
       color: #fff;
       z-index: 2;
       text-shadow: 0 2px 4px rgba(0,0,0,0.2);
       margin-bottom: 0;
       font-size: calc(40rem/16);
   }
}