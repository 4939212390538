
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fade-in{
    opacity: 0;
    animation: 1200ms ease-in-out 850ms 1 fadeIn;
    animation-fill-mode: forwards;
}

.fade-in-hero{
    opacity: 0;
    animation: 1200ms ease-in-out 250ms 1 fadeIn;
    animation-fill-mode: forwards;
}