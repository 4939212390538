.cookie-bar{
    z-index: 999;
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}
.cookie-bar__buttons .btn,
.cookie-modal .modal-footer .btn{
    @media screen and (max-width: 767px){
       font-size: calc(11rem/16);
        padding: calc(7rem/16) calc(15rem/16);
    }
}

.cookie-bar__detail-link{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}

.cookie-bar__detail-link+.cookie-bar__detail-link{
    margin-left: 0;
}

.cookie-bar__detail-link:not(:last-child){
    margin-right: calc(15rem/16);
}

.cookie-modal__label{
    font-size: calc(18rem/16);
    
    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
    }
}