th{
    font-family: var(--font-default-medium);
}

.wysiwyg table{
    width: 100%;
}

.wysiwyg table>thead{
    border-bottom: calc(6rem/16) solid var(--color-primary-light);
}

.wysiwyg table>tbody>tr{
    border-bottom: calc(2rem/16) solid var(--color-primary-light);
}

.wysiwyg table td,
.wysiwyg table th{
    padding: calc(15rem/16) calc(20rem/16);
    vertical-align: top;
}

.table-striped tbody tr:nth-of-type(odd){
    background-color: var(--color-primary-light);
}

.table--aufguss td,
.table--aufguss th{
    @media screen and (max-width: 767px){
        padding: calc(12rem/16) calc(7rem/16);
        border-right: 1px solid #dee2e6;
        font-size: calc(13rem/16);
    }
}

.table--aufguss td:first-child,
.table--aufguss th:first-child{
    @media screen and (max-width: 767px){
        border-left: 1px solid #dee2e6;

    }
}

.table--aufguss td .icon{
    vertical-align: calc(-2rem/16);
    margin-right: calc(3rem/16);
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(19rem/16);
    }
}

.table--aufguss__highlight>td:first-child{
    border-left: 4px solid var(--color-primary);
}

.table--aufguss{
    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
    }
}

.table--aufguss td{
    @media screen and (max-width: 767px){
        white-space: nowrap;
    }
}